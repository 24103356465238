import { useState, useEffect, useContext } from "react";
import ac from './img/ac.png';
import dc from './img/dc.png';
import qu from './img/qu.png';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import back from './img/back.png';
import Moment from 'moment';


const NewTravelExpense = () => {

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");

    var headerImg = document.getElementById("headerImg");

    const id = localStorage.getItem("id");
    const company = localStorage.getItem('company');

    const [desces, setDesc] = useState([ ]);
    const [countries, setCountries] = useState([ ]);

    const [userInfo, setUserInfo] = useState([ ]);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':localStorage.getItem('company')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/get-countries.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => setCountries(data.body))
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': id}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserInfo(data);
                changeLanguage(data.language);
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':4, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
            }
        )
        .catch((error) => {}); 
    }

    const [types, setTypes] = useState([ ]);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': localStorage.getItem('company')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-type.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                setTypes(data.body);
            });
    }, []);

    function loadDescription(type){
        for(var i=0;i<types.length;i++){
            if(types[i].typeID==type){
                setType(types[i].name);
                setTypeID(types[i].type);
            }
        }
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'typeID': type}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-description.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  setDesc(data.body);
                }
            )
            .catch((error) => {
            }); 
    }

    function setdescription(desc){
        for(var i=0;i<desces.length;i++){
            if(desces[i].descriptionID==desc){
                setDescription(desces[i].name);
                setDescID(desces[i].description);
                setContoID(desces[i].conto);
                setCoefficient(desces[i].coefficient);
            }
        }
    }

    const [open, setOpen] = useState(false);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    const handleClickToOpenDialog = () => {
        setLoadingDialog(true);
    };
  
    const handleToCloseDialog = () => {
        setLoadingDialog(false);
    };

    const [loadingDialog, setLoadingDialog] = useState(false);

    const [paymant, setPaymant] = useState('');
    const [country, setCountry] = useState('');
    const [type, setType] = useState('');
    const [typeID, setTypeID] = useState('');
    const [descID, setDescID] = useState('');
    const [coefficient, setCoefficient] = useState(1);
    const [contoID, setContoID] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [comment, setComment] = useState("");
    const [expenseID, setExpenseID] = useState(" ");

    function newExpense(event){
        event.preventDefault();
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'paymant': paymant, 'country': country, 'type': type, "description": description, "date":date, "comment":comment, "userID": id, "company": company, "typeID": typeID, "descID": descID, "contoID": contoID, "coefficient": coefficient}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/create-expense.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  uplaodImages(data.expenseID);
                }
            ); 
    }

    const [selectedFile, setSelectedFile] = useState(null);
    const [images, setImages] = useState([ ]);

    function upload(event, file){
        event.preventDefault();
        console.log(file);
        var formdata = new FormData();
        formdata.append("fileToUpload", file);
        const requestOptions = {
            method: 'POST',
            body: formdata,
        };
        fetch('https://tem.infokrugsolutions.com/upload.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                images.push(data.body);
                handleClickToOpen();
                handleToCloseDialog();
            });
        }

    function createImage(image,expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense, 'src':image}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/image/create-image.php', requestOptions)
            .then(response => response.json())
            .then(data => images.push(data.body))
    }

    function createAction(expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense, 'title':'Created by/Erstellt von', 'user': userInfo.username,'date': getCurrentDate()}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/action/create-action.php', requestOptions)
            .then(response => response.json())
            .then(data => { 
                window.location.href='/home'; 
            })
    }

    function uplaodImages(expense){
        for(var i=0;i<images.length;i++){
            createImage(images[i], expense);
        }
        createAction(expense);
    }

    function getCurrentDate() { 
        var date = new Date(); 
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2,'0');
        const dd = String(date.getDate()).padStart(2,'0');
    
        return dd + "." + mm + "." + yyyy;
    }

    return (        
        <div className="newTravelExpense">
            <form onSubmit={newExpense}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/home'}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="firstLine">
                <h2>{label2}</h2>
                <button type="submit" className="btnSave">{label17}</button>
            </div>
            <div className="inputBox">
                <label for="html">{label3}</label><br />
                <input type="text" onInput={event => event.target.value = event.target.value.replace(/[^0-9,-]/g, '').replace(/(\.,-*?)\.,*/g, '$1').replace(/^0[^,-]/, '0')} placeholder={label3} onChange={event => setPaymant(event.target.value)} required/> 
                <button type="button" className="pictureBtn" onClick={handleClickToOpen}>{label12}</button><br />
                <label for="html">{label4}</label><br />
                <select name="country" id="country" onChange={event => setCountry(event.target.value)} required>
                    <option value="">{label5}</option>
                    {countries.map((country) => (
                        <option value={country.name}>{country.name}</option>
                    ))}
                </select> <br />
                <label for="html">{label6}</label><br />
                <select name="type" id="type" onChange={event => {loadDescription(event.target.value);}} required>
                    <option value="">{label7}</option>
                    {types.map((type) => (
                        <option value={type.typeID}>{type.name}</option>
                    ))}
                </select> <br />
                <label for="html">{label8}</label><br />
                <select name="desc" id="desc" onChange={event => setdescription(event.target.value)} required>
                    <option value="">{label9}</option>
                    {desces.map((desc) => (
                        <option value={desc.descriptionID}>{desc.name}</option>
                    ))}
                </select> <br />
                <label for="html">{label10}</label><br />
                <input type="date" placeholder="dd-mm-yyyy" min="2022-01-01" max="2032-12-31" onChange={event => setDate(event.target.value)} required/> <br />
                <label for="html">{label11}</label><br />
                <input type="text" placeholder={label11} className="inputCmnt" onChange={event => setComment(event.target.value)}/>
            </div>
            </form>
            <Dialog open={open}>
                <DialogTitle>{label13}</DialogTitle>
                <DialogContent>
                    <form className="fileForm">  
                        <input className="inputfile" type="file" name="fileToUpload" id="fileToUpload" onChange={(e) => {handleToClose(); handleClickToOpenDialog(); upload(e, e.target.files[0]);}}/>
                        <label className="inputLabel" for="fileToUpload">{label14}</label><br/><br/>
                        {images.map((image) => (
                        <a href={image}><img src={image} className="imgUpload" alt="Image" width="100" height="100" /></a>
                    ))}<br/>
                    </form> 
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose} color="#286980" autoFocus>{label15}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={loadingDialog}>
                <DialogTitle>{label16}</DialogTitle>
                <DialogContent>
                <div className="loader"></div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default NewTravelExpense