import React from "react";
import { useState, useEffect, useContext } from "react";
import ac from './img/ac.png';
import dc from './img/dc.png';
import qu from './img/qu.png';
import dropdown from './img/dropdown.png';
import logout from './img/shutdown.png';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Moment from 'moment';

const Bin = () => {

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");
    const [label18, setLabel18] = useState("");
    const [label19, setLabel19] = useState("");
    const [label20, setLabel20] = useState("");
    const [label21, setLabel21] = useState("");
    const [label22, setLabel22] = useState("");
    const [label23, setLabel23] = useState("");
    const [label24, setLabel24] = useState("");
    const [label25, setLabel25] = useState("");
    const [label26, setLabel26] = useState("");
    const [label27, setLabel27] = useState("");
    const [label28, setLabel28] = useState("");
    const [label29, setLabel29] = useState("");
    const [label30, setLabel30] = useState("");
    const [label31, setLabel31] = useState("");
    const [label32, setLabel32] = useState("");

    const userID = localStorage.getItem('id');

    const [open, setOpen] = useState(false);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    const [open1, setOpen1] = useState(false);

    const handleClickToOpen1 = () => {
        setOpen1(true);
    };
  
    const handleToClose1 = () => {
        setOpen1(false);
    };

    const [paymantSelected, setpaymantSelected] = useState(' ');
    const [countrySelected, setcountrySelected] = useState(' ');
    const [typeSelected, setTypeSelected] = useState(' ');
    const [descSelected, setdescSelected] = useState(' ');
    const [dateSelected, setdateSelected] = useState(' ');
    const [commentSelected, setCommentSelected] = useState(' ');
    const [approvedSelected, setApprovedSelected] = useState(' ');
    const [placeSelected, setPlaceSelected] = useState(' ');
    const [rtnDateSelected, setRtnDateSelected] = useState(' ');
    const [rtnTimeSelected, setRntTimeSelected] = useState(' ');
    const [arrTimeSelected, setArrTimeSelected] = useState(' ');

    const [expenses, setExpenses] = useState([ ]);
    const [userInfo, setUserInfo] = useState([ ]);
    const [app, setApp] = useState(0);
    const [dates, setDates] = useState([ ]);
    const [users, setUsers] = useState([ ]);
    const [images, setImages] = useState([ ]);
    
    const [date1, setDate1] = useState("");
    const [date2, setDate2] = useState("");
    const [userFilter, setUserFilter] = useState("");

    const [actions, setAction] = useState([ ]);

    function getExpensesById(id1, id2){
        localStorage.setItem("user", id2);
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'userID': id2, 'expenseID':id1}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/get-expense-by-id.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
             })
            .then(data => {
                if(data.error!="true"){
                setImages([]);
                details(data);
                getImages(id1);
                }
            })
            .catch((error) => {
            });
    }

    function downloadReport(){
        window.open('https://tem.infokrugsolutions.com/back/api/export-word.php').focus(); 
        window.location.href='/archive';
    }

    function getImages(id){
        localStorage.setItem("expense", id);
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID':id}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/image/get-images.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
             })
            .then(data => {
                if(data.error!="true"){
                setImages(data.body);
                }
            })
            .catch((error) => {
            });
    }

    function details(expense, tag){
        actionHistory(expense);
        setApprovedSelected(expense.approved)
        setpaymantSelected(expense.paymant);
        setcountrySelected(expense.country);
        setTypeSelected(expense.type);
        setdescSelected(expense.description);
        setdateSelected(expense.date);
        setCommentSelected(expense.comment);
        setPlaceSelected(expense.place);
        setRtnDateSelected(expense.rtnDate);
        setRntTimeSelected(expense.rtnTime);
        setArrTimeSelected(expense.arrTime);
        if(expense.tag=="1"){
            handleClickToOpen1();
        }
        else{
            handleClickToOpen();
        }
    }

    function actionHistory(expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense.expenseID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/action/get-action.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                setAction(data.body);
                }
            })
            .catch((error) => {
            }); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': userID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                localStorage.setItem("company", data.company)
                setUserInfo(data);
                changeLanguage(data.language);
                getExpenses();
                }
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':6, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
              setLabel18(data.body[17].label);
              setLabel19(data.body[18].label);
              setLabel20(data.body[19].label);
              setLabel21(data.body[20].label);
              setLabel22(data.body[21].label);
              setLabel23(data.body[22].label); 
              setLabel24(data.body[23].label); 
              setLabel25(data.body[24].label); 
              setLabel26(data.body[25].label); 
              setLabel27(data.body[26].label); 
              setLabel28(data.body[27].label); 
              setLabel29(data.body[28].label); 
              setLabel30(data.body[29].label); 
              setLabel31(data.body[30].label); 
              setLabel32(data.body[31].label); 
            }
            }
        )
        .catch((error) => {}); 
    }

    function getExpenses() {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': localStorage.getItem("company")}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/get-company-expenses.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                if(data.error!="true"){
                for(var i=0;i<data.itemCount;i++){
                    if(data.body[i].archive==-1){
                        expenses.push(data.body[i]);
                    }
                }
                for(var i=0;i<data.itemCount;i++){
                    dates.push(data.body[i].date);
                    users.push(data.body[i].user.username)
                }
                const dates2 = [...new Set(dates)];
                const users2 = [...new Set(users)];
                setDates(dates2);
                setUsers(users2);
            }
            })
            .catch((error) => {
            });
    };

    function filterExpenses(filter1, filter2, filter3){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': localStorage.getItem("company")}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/get-company-expenses.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => { 
                if(data.error!="true"){               
                expenses.length = 0;
                const help = [];
                if(filter1!="" && filter2!=""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date >= filter1 && data.body[i].date <= filter2){
                            if(data.body[i].archive==1){
                            help.push(data.body[i]);}
                        }
                    }
                }
                else if(filter1!="" && filter2==""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date >= filter1){
                            if(data.body[i].archive==1){
                            help.push(data.body[i]);}
                        }
                    }
                }
                else if(filter1=="" && filter2!=""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date <= filter2){
                            if(data.body[i].archive==1){
                            help.push(data.body[i]);}
                        }
                    }
                }
                else if(filter1=="" && filter2==""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].archive==1){
                        help.push(data.body[i]);}
                    }
                }

                const final = [];

                if(filter3!=""){
                    for(var i=0;i<help.length;i++){
                        if(help[i].user.username == filter3){
                            final.push(help[i]);
                        }
                    }
                    setExpenses(final);
                }
                else{
                    setExpenses(help);
                }
            }
            })
            .catch((error) => {

            }); 
    }

    function navigation(event){
        if(event=="1"){
            window.location.href='/supervisor-board';
        }
        else if(event=="3"){
            window.location.href='/archive';
        }
        else if(event=="2"){
            window.location.href='/recycle-bin';
        }
    }

    function logoutFun(){
        localStorage.clear();
        window.location.href='/login';
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function getDateFormated(event) { 
        var date = new Date(Date.parse(event)); 
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2,'0');
        const dd = String(date.getDate()).padStart(2,'0');
    
        return dd + "." + mm + "." + yyyy + ".";
    }

    return(        
        <div className="home">
            <h1>{label4}</h1>
            <div className="header">
                <div className="dropdown">
                    <button className="dropbtn">{userInfo.name} {userInfo.surname} <img src={dropdown} /></button>
                    <div className="dropdown-content">
                        <a href="#" onClick={()=> window.location.href='/profile-settings'}>{label15}</a>
                        <a href="#" onClick={logoutFun}>{label16}</a>
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <select className="navigation" name="view" id="view" onChange={event => {navigation(event.target.value)}}>
                    <option value="2">{label4}</option>
                    <option value="1">{label2}</option>
                    <option value="3">{label3}</option>
                </select>
                <input className="first" type="date" id="date" min="2022-01-01" max="2032-12-31" onChange={event => {setDate1(event.target.value); filterExpenses(event.target.value, date2, userFilter);}} placeholder={details.date} /> 
                <input className="sec" type="date" id="date" min="2022-01-01" max="2032-12-31" onChange={event => {setDate2(event.target.value); filterExpenses(date1, event.target.value, userFilter);}} placeholder={details.date} /> 
                <select className="user" name="view" id="view"  onChange={event => {setUserFilter(event.target.value); filterExpenses(date1, date2, event.target.value);}}>
                    <option value="">{label14}</option>
                    {users.map((us) => (
                        <option value={us}>{us}</option>
                    ))}
                </select>
            </div>
            <div className="visorTable">
                <table className="data">
                    <tr>
                        <th>{label6}</th>
                        <th>{label7}</th>
                        <th className="firstClass">{label8}</th>
                        <th className="secondClass">{label9}</th>
                        <th className="firstClass">{label10}</th>
                        <th>{label11}</th>
                    </tr>
                    {expenses.map((expense) => (
                        <tr>
                            <td onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{expense.user.username}</td>
                            <td onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{numberWithCommas(expense.paymant)}</td> 
                            <td className="firstClass" onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{expense.country}</td>
                            <td className="secondClass" onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{expense.type}</td>
                            <td className="firstClass" onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{expense.description}</td>
                            <td onClick={event => getExpensesById(expense.expenseID, expense.userID)}>{getDateFormated(expense.date)}</td>
                        </tr>
                    ))}
                </table> <br />
            </div>
            <Dialog open={open}>
                <DialogTitle>{label20}</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="detailsView">
                            <hr/>
                            <p>{label7}: {numberWithCommas(paymantSelected)}</p><br/>
                            <p>{label8}: {countrySelected}</p><br/>
                            <p>{label9}: {typeSelected}</p><br/>
                            <p>{label10}: {descSelected}</p><br/>
                            <p>{label21}: {commentSelected==" " ? <span>/</span> : <span>{commentSelected}</span>}</p><br />
                            <p>{label22}: </p><br />
                            {images.map((image) => (
                                <a href={image.src} target="_blank"><img src={image.src} className="imgUpload" alt="Image" width="100" height="100" /></a>
                            ))}
                            <br/>
                            <hr/>
                            {actions.map((action) => (
                                <p className="action">{getDateFormated(action.date)} | {action.title} {action.user}</p>
                            ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose} color="#286980">{label24}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open1}>
                <DialogTitle>{label26}</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="detailsView">
                        <p>{label8}: {countrySelected}</p><br/>
                            <p>{label27}: {placeSelected}</p><br/>
                            <p>{label28}: {getDateFormated(dateSelected)}</p><br/>
                            <p>{label29}: {arrTimeSelected}</p><br/>
                            <p>{label30}: {getDateFormated(rtnDateSelected)}</p><br/>
                            <p>{label31}: {rtnTimeSelected}</p><br/>
                            <p>{label21}: {commentSelected}</p><br/>
                            <hr/>
                            {actions.map((action) => (
                                <p className="action">{getDateFormated(action.date)} | {action.title} {action.user}</p>
                            ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose1} color="#286980">{label24}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Bin