import React from "react";
import { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { CookiesProvider, useCookies  } from "react-cookie";


const Block = ({logo}) => {

    const [emailVal, setEmail] = useState('');
    const [passwordVal, setPassword] = useState('');
    const [langs, setLangs] = useState([ ]);
    const [labels, setLabels] = useState([ ]);
    const [loginData, setLoginData] = useState('');
    const [label1, setLabel1] = useState('');
    const [label2, setLabel2] = useState('');
    const [label3, setLabel3] = useState('');
    const [label4, setLabel4] = useState('');
    const [label5, setLabel5] = useState('');
    const [label6, setLabel6] = useState('');
    const [label7, setLabel7] = useState('');
    const [label8, setLabel8] = useState('');
    const [label9, setLabel9] = useState('');
    const [label10, setLabel10] = useState('');
    const [open, setOpen] = useState(false);
    const [responseSuccess, setResponseSuccess] = useState(false);

    const [cookies, setCookie] = useCookies(['user']);
    
    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    function login() {
        var um = emailVal;
        var pw = passwordVal;
        let user = {email:um, password:pw}
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(user),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/login-data-check.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { setResponseSuccess(true); return response.json();}
              })
            .then(data => { 
                if(data.error!="true"){
                localStorage.setItem('id', data.body[0].id); 
                localStorage.setItem('role', data.body[0].role);
                localStorage.setItem('language', data.body[0].language);
                redirectHome();
                }
                else{
                    handleClickToOpen();
                }
            })
            .catch((error) => {
                handleClickToOpen();
            });
    }

    function redirectHome(){
        if(localStorage.getItem('role')==2){
            localStorage.setItem('logged', true);
            window.location.href='/home';
        }
        else if(localStorage.getItem('role')==1){
            localStorage.setItem('logged', true);
            window.location.href='/supervisor-board';
        }
        else if(localStorage.getItem('role')==0){
            localStorage.setItem('logged', true);
            window.location.href='/superadmin-board';
        }
    }

    useEffect(() => {
        changeLanguage(2);
    }, []);

    function changeLanguage(event) {
        localStorage.clear()
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':1, 'language':event}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
            }
            }
        )
        .catch((error) => {}); 
    }

    return(        
        <div className="block">
            <div className="login-form">
                <img src={logo} alt="Logo" className="login-logo" />
                <h1>{label1}</h1>
                <h2>{label2}</h2>
                <form>
                    <input type="text" id="email" placeholder={label3} onChange={event => setEmail(event.target.value)} /><br />
                    <input type="password" id="password" placeholder={label4} onChange={event => setPassword(event.target.value)} /><br />
                    <button type="button" onClick={login}>{label6}</button>
                </form>
            </div>
            <div className="language-form">
                <select name="language" id="languages" onChange={(event) => {changeLanguage(event.target.value)}}>
                <option value="2">{label8}</option>
                <option value="1">{label7}</option>
                </select>
            </div>
            <Dialog open={open} onClose={handleClickToOpen}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{label9}</DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose} color="#286980" autoFocus>{label10}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Block