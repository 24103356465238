import { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import back from './img/back.png';

const NewTravelOrder = () => {

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");

    var id = localStorage.getItem("id");
    var company = localStorage.getItem('company');

    var headerImg = document.getElementById("headerImg");

    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState(false);
    const [countryID, setCountryID] = useState("");
    const [place, setPlace] = useState(false);
    const [date, setDate] = useState(false);
    const [arrTime, setArrTime] = useState(false);
    const [rtnDate, setRtnDate] = useState(false);
    const [rtnTime, setRtnTime] = useState(false);
    const [comment, setComment] = useState("");
    const [userInfo, setUserInfo] = useState([ ]);
    const [first, setFirst] = useState(0);
    const [second, setSecond] = useState(0);
    const [third, setThird] = useState(0);

    const [countries, setCountries] = useState([ ]);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':localStorage.getItem('company')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/get-countries.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => setCountries(data.body))
            .catch((error) => {
            });
    }, []);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    function setCountryFun(c){
        setCountryID(c);
        for(var i=0;i<countries.length;i++){
            if(countries[i].id==c){
                setCountry(countries[i].name);
                setFirst(countries[i].first);
                setSecond(countries[i].second);
                setThird(countries[i].third);
            }
        }
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': id}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserInfo(data);
                changeLanguage(data.language);
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':3, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
            }
        )
        .catch((error) => {}); 
    }

    function newOrder(event){
        event.preventDefault();
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({'country': country, 'comment':comment, 'countryID':countryID, 'place': place, 'date': date, "arrTime": arrTime, "rtnDate":rtnDate, "rtnTime":rtnTime, "userID": id, "company":company, "first":first, "second":second, "third":third}),
            };
            fetch('https://tem.infokrugsolutions.com/back/api/expense/create-order.php', requestOptions)
            .then(response => response.json())
            .then(data => { 
                    createAction(data.expenseID);
            });
    }

    function createAction(expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense, 'title':'Created by/Erstellt von', 'user': userInfo.username,'date': getCurrentDate()}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/action/create-action.php', requestOptions)
            .then(response => response.json())
            .then(data => { 
                window.location.href='/home'; 
            })
    }

    function getCurrentDate() { 
        var date = new Date(); 
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2,'0');
        const dd = String(date.getDate()).padStart(2,'0');
    
        return dd + "." + mm + "." + yyyy;
    }
   
    return (        
        <div className="newTravelOrder">
            <form onSubmit={newOrder}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/home'}><img src={back} id="headerImg"/> {label1}</h1>
                <div className="firstLine">
                    <h2>{label2}</h2>
                    <button type="submit" className="btnSave">{label9}</button>
                </div>
            
                <div className="inputBox">
                    <label for="html">{label3}</label><br />
                    <select name="country" id="country" onChange={event => setCountryFun(event.target.value)} required>
                    <option value="">{label4}</option>
                    {countries.map((country) => (
                        <option value={country.id}>{country.name}</option>
                    ))}
                    </select><br />
                    <label for="html">{label5}</label><br />
                    <input type="text" placeholder={label5} onChange={event => setPlace(event.target.value)} required/><br />
                    <label for="html">{label6}</label><br />
                    <input type="text" placeholder="Comment" onChange={event => setComment(event.target.value)}/><br />
                    <label for="html">{label7}</label><br />
                    <input type="date" placeholder="dd-mm-yyyy" min="2022-01-01" max="2032-12-31" onChange={event => setDate(event.target.value)} required/>
                    <input type="time" placeholder="Arrival time" onChange={event => setArrTime(event.target.value)} required/><br />
                    <label for="html">{label8}</label><br />
                    <input type="date" placeholder="dd-mm-yyyy" min="2022-01-01" max="2032-12-31" onChange={event => setRtnDate(event.target.value)} required/>
                    <input className="return" type="time" placeholder="Return time" onChange={event => setRtnTime(event.target.value)} required/><br />
                </div>
            </form>
        </div>
    )
}

export default NewTravelOrder