import { useState, useEffect, useContext } from "react";
import add from './img/add.png';
import clear from './img/clear.png';
import back from './img/back.png';


const CreateCompany = () => {

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");
    const [label18, setLabel18] = useState("");
    const [label19, setLabel19] = useState("");
    const [label20, setLabel20] = useState("");
    const [label21, setLabel21] = useState("");
    const [label22, setLabel22] = useState("");
    const [label23, setLabel23] = useState("");
    const [label24, setLabel24] = useState("");
    const [label25, setLabel25] = useState("");
    const [label26, setLabel26] = useState("");

    const [cmp, setCmp] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [postal, setPostal] = useState('');
    const [tax, setTax] = useState('');
    const [vatN, setVatN] = useState('');
    const [name, setName] = useState('');
    const [details, setDetails] = useState([ ]);

    const [typeName, setTypeName] = useState('');
    const label1a = localStorage.getItem('companyName');
    const company = localStorage.getItem('companyID');
    const [type, setType] = useState([ ]);
    const [typeID, setTypeID] = useState([ ]);
    const [desc, setDesc] = useState([ ]);
    const [description, setDescription] = useState([ ]);
    const [newType, setNewType] = useState('');
    const [newDesc, setNewDesc] = useState('');
    const [conto, setConto] = useState('');
    const [vat, setVat] = useState('');
    const [coefficient, setCoefficient] = useState('');
    const [visible, setVisible] = useState(true);
    const [countries, setCountries] = useState([ ]);
    const [country, setCountry] = useState('');
    const [first, setFirst] = useState('');
    const [second, setSecond] = useState('');
    const [third, setThird] = useState('');
    const [ubernachtung, setUbernachtung] = useState('');

    var headerImg = document.getElementById("headerImg");

    useEffect(() => {
        loadType();
        loadCountry();
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('id')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                changeLanguage(data.language);
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':8, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
              setLabel18(data.body[17].label);
              setLabel19(data.body[18].label);
              setLabel20(data.body[19].label);
              setLabel21(data.body[20].label);
              setLabel22(data.body[21].label);
              setLabel23(data.body[22].label); 
              setLabel24(data.body[23].label); 
              setLabel25(data.body[24].label); 
              setLabel26(data.body[25].label); 
            }
        )
        .catch((error) => {}); 
    }

    function loadCountry() {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':company}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/get-countries.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                if(data.error!="true"){
                setCountries(data.body);
                }
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'companyID':company}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/company/get-company-by-id.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data =>{
                document.getElementById('name').value=decodeHTMLEntities(data.body[0].name);
                setName(data.body[0].name);
                document.getElementById('id').value=data.body[0].cmp;
                setCmp(data.body[0].cmp);
                document.getElementById('add1').value=data.body[0].address1;
                setAddress1(data.body[0].address1);
                document.getElementById('add2').value=data.body[0].address2;
                setAddress2(data.body[0].address2);
                document.getElementById('city').value=data.body[0].city;
                setCity(data.body[0].city);
                document.getElementById('code').value=data.body[0].postal;
                setPostal(data.body[0].postal);
                document.getElementById('tax').value=data.body[0].tax;
                setTax(data.body[0].tax);
                document.getElementById('vatN').value=data.body[0].vat;
                setVatN(data.body[0].vat);
            })
            .catch((error) => {
            });
    }, []);

    function loadType(){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':company}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-type.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                if(data.error!="true"){
                    setType(data.body);
                }
                }
            )
            .catch((error) => {
            }); 
    }

    function createCompany(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'companyID':company, 'name': name, 'cmp': cmp, 'address1': address1, 'address2': address2, 'city':city, 'postal':postal, 'tax':tax, 'vat':vatN}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/company/update-company.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                   window.location.href='/superadmin-board';
                }
            )
            .catch((error) => {
            }); 
    }

    function createType(event){
        if(typeName!=""){
            event.preventDefault();
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({'name':typeName, 'company':company, 'type':newType}),
            };
            fetch('https://tem.infokrugsolutions.com/back/api/configuration/create-type.php', requestOptions)
                .then((response) => {
                    if(!response.ok) throw new Error(response.status);
                    else { return response.json();}
                  })
                .then(
                  data => {
                    document.getElementById('type1').value="";
                    document.getElementById('type2').value="";
                    loadType();
                    }
                )
                .catch((error) => {
                }); 
        }
    }

    function deleteType(event, typeID){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'typeID': typeID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/delete-type.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  loadType();
                }
            )
            .catch((error) => {
            }); 
    }

    function loadDescription(typeID){
        setVisible(false);
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'typeID': typeID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-description.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                if(data.error!="true"){
                  setDescription(data.body);
                  setTypeID(typeID);
                }
                }
            )
            .catch((error) => {
            }); 
    }

    function deleteDescription(event, descriptionID, typeID){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'descriptionID': descriptionID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/delete-description.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  loadDescription(typeID);
                }
            )
            .catch((error) => {
            }); 
    }

    function deleteCountry(event, countryID){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'countryID': countryID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/delete-country.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  loadCountry();
                }
            )
            .catch((error) => {
            }); 
    }

    function createDescription(event){
        if(desc!=""){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'typeID': typeID, 'name':desc, 'description': newDesc, 'conto': conto, 'vat':vat, 'coefficient':coefficient}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/create-description.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                document.getElementById('desc1').value="";
                document.getElementById('desc2').value="";
                document.getElementById('desc3').value="";
                document.getElementById('desc4').value="";
                document.getElementById('desc5').value="";
                  loadDescription(typeID);
                }
            )
            .catch((error) => {
            }); }
    }

    function createCountry(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'name':country, 'company':company, 'first':first, 'second':second, 'third':third, 'ubernachtung':ubernachtung}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/create-country.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
                })
            .then(
                data => {
                document.getElementById('country1').value="";
                document.getElementById('country2').value="";
                document.getElementById('country3').value="";
                document.getElementById('country4').value="";
                document.getElementById('country5').value="";
                loadCountry();
                }
            )
            .catch((error) => {
        }); 
    }

    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    return (        
        <div className="newTravelExpense">
            <form onSubmit={createCompany}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/superadmin-board'}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="firstLine">
                <h2>{label14}</h2>
                <button type="submit" className="btnSave">{label13}</button>
            </div>
            <div className="inputBox">
                <label for="html">{label3}</label><br /><br />
                <input type="text" id="name" placeholder={label4} onChange={event => setName(event.target.value)} required/>
                <input type="text" id="id" placeholder={label5} onChange={event => setCmp(event.target.value)} required/> <br /><br />
                <input type="text" id="add1" placeholder={label6} onChange={event => setAddress1(event.target.value)} required/> 
                <input type="text" id="add2" placeholder={label7} onChange={event => setAddress2(event.target.value)} /><br /><br />
                <input type="text" id="city" placeholder={label8} onChange={event => setCity(event.target.value)} required/><br /><br />
                <input type="text" id="code" placeholder={label9} onChange={event => setPostal(event.target.value)} required/><br /><br />
                <input type="text" id="tax" placeholder={label11} onChange={event => setTax(event.target.value)} required/> 
                <input type="text" id="vatN" className="inputLast" placeholder={label12} onChange={event => setVatN(event.target.value)} required/> 
            </div>
            </form>
            <form>
                <div className="configurationBox">
                    <label for="html">{label15}</label><br />
                    <input type="text" className="confInput" id="type1" placeholder={label16} onChange={event => setTypeName(event.target.value)} required/>
                    <input type="text" className="confInputParams" id="type2" placeholder={label17} onChange={event => setNewType(event.target.value)} required/>
                    <img className="confIcon" onClick={event => createType(event)}src={add} /><br /><br />
                    <table className="confTable">
                    {type.map((t) => (
                        <tr>
                            <td><input type="radio" id="selectAll" name="selectAll" value={t.name} onChange={event => loadDescription(t.typeID)}/>{t.name} ({t.type})</td>
                            <th><img className="confIcon" onClick={event => deleteType(event, t.typeID)} src={clear} /></th>
                        </tr>
                    ))}    
                    </table>        
                </div> 
            </form>
            <form hidden={visible}>
                <div className="configurationBox">
                    <label for="html">{label18}</label><br />
                    <input type="text" className="confInput" placeholder={label19} id="desc1" onChange={event => setDesc(event.target.value)} required/>
                    <input type="text" className="confInputParams" placeholder={label20} id="desc2" onChange={event => setNewDesc(event.target.value)} required/>
                    <input type="text" className="confInputParams" placeholder="Accountr" id="desc3" onChange={event => setConto(event.target.value)} required/>
                    <input type="text" className="confInputParams" placeholder={label12} id="desc4" onChange={event => setVat(event.target.value)} required/>
                    <input type="text" className="confInputParams" placeholder="Coefficient" id="desc5" onChange={event => setCoefficient(event.target.value)} required/>
                    <img className="confIcon" onClick={event => createDescription(event)} src={add} /><br /><br />
                    <table className="confTable">
                    {description.map((d) => (
                        <tr>
                            <td>{d.name} ({d.description}) ({d.conto}) ({d.vat}) ({d.coefficient})</td>
                            <th><img className="confIcon" onClick={event => deleteDescription(event, d.descriptionID, d.typeID)} src={clear} /></th>
                        </tr>
                    ))}    
                    </table>        
                </div> 
            </form>
            <form>
                <div className="configurationBox">
                    <label for="html">{label21}</label><br />
                    <input type="text" className="confInput" id="country1" placeholder={label22} onChange={event => setCountry(event.target.value)} required/>
                    <input type="text" className="confInputParams" id="country2" placeholder={label23} onChange={event => setFirst(event.target.value)} required/>
                    <input type="text" className="confInputParams" id="country3" placeholder={label24} onChange={event => setSecond(event.target.value)} required/>
                    <input type="text" className="confInputParams" id="country4" placeholder={label25} onChange={event => setThird(event.target.value)} required/>
                    <input type="text" className="confInputParams" id="country5" placeholder={label26} onChange={event => setUbernachtung(event.target.value)} required/>
                    <img className="confIcon" onClick={event => createCountry(event)}src={add} /><br /><br />
                    <table className="confTable">
                    {countries.map((t) => (
                        <tr>
                            <td>{t.name} ({t.first}) ({t.second}) ({t.third}) ({t.ubernachtung})</td>
                            <th><img className="confIcon" onClick={event => deleteCountry(event, t.id)} src={clear} /></th>
                        </tr>
                    ))}    
                    </table>        
                </div> 
            </form>
        </div>
    )
}

export default CreateCompany