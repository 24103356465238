import React from "react";
import { useState, useEffect, useContext } from "react";
import ac from './img/ac.png';
import dc from './img/dc.png';
import qu from './img/qu.png';
import dropdown from './img/dropdown.png';
import logout from './img/shutdown.png';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import back from './img/back.png';


const VisorList = () => {
    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");


    const userID = localStorage.getItem('id');
    const company = localStorage.getItem('companyID');
    const [userInfo, setUserInfo] = useState([ ]);
    const [users, setUsers] = useState([ ]);

    const [open, setOpen] = useState(false);

    var headerImg = document.getElementById("headerImg");

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': userID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                changeLanguage(data.language);
                setUserInfo(data);
                }
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':9, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
            }
            }
        )
        .catch((error) => {}); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': localStorage.getItem('companyID'), "role": 1}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/all-users.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                if(data.error!="true"){
                    console.log(data.error);
                    console.log("TEST")
                    if(data.error!="true"){
                    setUsers(data.body);
                    }
                }
            })
            .catch((error) => {
            });
    }, []);

    function deleteUser(event){
        let check = window.confirm(label11);

        if(check){
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({'id': event}),
            };
            fetch('https://tem.infokrugsolutions.com/back/api/user/delete-user.php', requestOptions)
                .then((response) => {
                    if(!response.ok) throw new Error(response.status);
                    else { return response.json();}
                })
                .then(data => {
                    window.location.href='/superadmin-board/company/supervisors';
                })
                .catch((error) => {
                });
        }
    }

    function logoutFun(){
        localStorage.clear();
        window.location.href='/login';
    }

    return(        
        <div className="home">
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/superadmin-board'}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="header">
                <div className="dropdown">
                    <button className="dropbtn">{userInfo.name} {userInfo.surname} <img src={dropdown} /></button>
                    <div className="dropdown-content">
                        <a href="#" onClick={()=> window.location.href='/profile-settings'}>{label9}</a>
                        <a href="#" onClick={logoutFun}>{label10}</a>
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <button className="buttonExpense" onClick={()=> { localStorage.setItem('createRole', 1); window.location.href='/superadmin-board/company/create-user';}}>{label8}</button>
            </div>
            <div className="visorTable">
                <table className="data">
                    <tr>
                        <th>{label2}</th>
                        <th>{label3}</th>
                        <th>{label4}</th>
                        <th>{label5}</th>
                        <th>{label6}</th>
                        <th>{label7}</th>
                    </tr>
                    {users.map((user) => (
                        <tr>
                            <td>{user.email}</td>
                            <td>{user.username}</td>
                            <td>{user.name}</td>
                            <td>{user.surname}</td>
                            <td onClick={()=> { localStorage.setItem('createRole', 1); localStorage.setItem('editId', user.id); window.location.href='/superadmin-board/company/update-user';}}><button className="basicButton">{label6}</button></td>
                            <td><button className="deleteButton" onClick={event => {deleteUser(user.id);}}>{label7}</button></td>
                        </tr>
                    ))}
                </table> <br />
            </div>
        </div>
    )
}

export default VisorList