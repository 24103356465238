import { useState, useEffect, useContext } from "react";
import ac from './img/ac.png';
import dc from './img/dc.png';
import qu from './img/qu.png';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import back from './img/back.png';

const EditExpense = () => {

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");

    var headerImg = document.getElementById("headerImg");

    const id = localStorage.getItem("id");
    const expenseID = localStorage.getItem('expense');
    const company = localStorage.getItem('company');

    const [typeID, setTypeID] = useState('');
    const [firstType, setFirstType] = useState('');
    const [descID, setDescID] = useState('');
    const [coefficient, setCoefficient] = useState(1);
    const [contoID, setContoID] = useState('');

    const [types, setTypes] = useState([ ]);
    const [desces, setDesc] = useState([ ]);
    const [countries, setCountries] = useState([ ]);

    const [details, setDetails] = useState([ ]);

    const [userInfo, setUserInfo] = useState([ ]);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': id}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                setUserInfo(data);
                changeLanguage(data.language);
                }
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':4, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
            }
            }
        )
        .catch((error) => {}); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': localStorage.getItem('company')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-type.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                setTypes(data.body);
                initialization();
                }
            });
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':localStorage.getItem('company')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/get-countries.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {if(data.error!="true"){setCountries(data.body); initialization();}})
            .catch((error) => {
            });
    }, []);

    function initialization() {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'userID': id, 'expenseID':expenseID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/get-expense-by-id.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
             })
            .then(data => {
                if(data.error!="true"){
                setDetails(data);
                document.getElementById('paymant').value=data.paymant;
                setPaymant(data.paymant);
                document.getElementById('country').value=data.country;
                setCountry(data.country);
                document.getElementById('typeLbl').value=data.type;
                setType(data.type);
                document.getElementById('descLbl').value=data.description;
                setDescription(data.description);
                document.getElementById('date').value=data.date;
                setDate(data.date);
                document.getElementById('comment').value=data.comment;
                setComment(data.comment);
                setTypeID(data.typeID);
                setFirstType(data.typeID);
                setDescID(data.descID);
                setCoefficient(data.coefficient);
                setContoID(data.contoID);
                loadDescription(data.typeID);
                }
            })
            .catch((error) => {
            });
    }

    const [open, setOpen] = useState(false);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    const handleClickToOpenDialog = () => {
        setLoadingDialog(true);
    };
  
    const handleToCloseDialog = () => {
        setLoadingDialog(false);
    };

    const [loadingDialog, setLoadingDialog] = useState(false);

    const [paymant, setPaymant] = useState('');
    const [country, setCountry] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [comment, setComment] = useState(" ");

   function newExpense(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'paymant': paymant, 'country': country, 'type': type, "description": description, "date":date, "comment":comment, "userID": id, "company": company, "expenseID":expenseID, "typeID":typeID, "descID":descID, "contoID":contoID, "coefficient": coefficient}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/expense/edit-expense.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  uplaodImages(expenseID);
                }
            ); 
    } 

    const [selectedFile, setSelectedFile] = useState(null);
    const [images, setImages] = useState([ ]);

    function upload(event, file){
        event.preventDefault();
        console.log(file);
        var formdata = new FormData();
        formdata.append("fileToUpload", file);
        const requestOptions = {
            method: 'POST',
            body: formdata,
        };
        fetch('https://tem.infokrugsolutions.com/upload.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                images.push(data.body);
                handleClickToOpen();
                handleToCloseDialog();
            });
        }

    function createImage(image,expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense, 'src':image}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/image/create-image.php', requestOptions)
            .then(response => response.json())
            .then(data => images.push(data.body))
    }
    
    function uplaodImages(expense){
        for(var i=0;i<images.length;i++){
            createImage(images[i], expense);
        }
        createAction(expense);
    } 
    
    function createAction(expense){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'expenseID': expense, 'title':'Edited by/Bearbeitet von', 'user': userInfo.username,'date': getCurrentDate()}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/action/create-action.php', requestOptions)
            .then(response => response.json())
            .then(data => { 
                window.location.href='/home';
            })
    }

    function loadDescription(type){
        for(var i=0;i<types.length;i++){
            if(types[i].typeID==type){
                setType(types[i].name);
                setTypeID(types[i].type);
                document.getElementById('typeLbl').value=types[i].name;
            }
        }
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'typeID': type}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/configuration/get-description.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  setDesc(data.body);
                }
            )
            .catch((error) => {
            }); 
    }

    function setdescription(desc){
        for(var i=0;i<desces.length;i++){
            if(desces[i].descriptionID==desc){
                setDescription(desces[i].name);
                document.getElementById('descLbl').value=desces[i].name;
                setDescID(desces[i].description);
                setCoefficient(desces[i].coefficient);
                setContoID(desces[i].conto);
            }
        }
    }

    function getCurrentDate() { 
        var date = new Date(); 
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2,'0');
        const dd = String(date.getDate()).padStart(2,'0');
    
        return dd + "." + mm + "." + yyyy;
    }

    return (        
        <div className="newTravelExpense">
        <form onSubmit={newExpense}>
        <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/home'}><img src={back} id="headerImg"/> {label1}</h1>
        <div className="firstLine">
            <button type="submit" className="btnSave">{label17}</button>
        </div>
        <div className="inputBox">
            <label for="html">{label3}</label><br />
            <input type="text" id="paymant" onInput={event => event.target.value = event.target.value.replace(/[^0-9,-]/g, '').replace(/(\.,-*?)\.,*/g, '$1').replace(/^0[^,-]/, '0')} onChange={event => setPaymant(event.target.value)} placeholder={details.paymant} required/> 
            <button type="button" className="pictureBtn" onClick={handleClickToOpen}>{label12}</button><br />
            <label for="html">{label4}</label><br />
            <select name="country" id="country" onChange={event => setCountry(event.target.value)} required>
                <option value="">{label5}</option>
                {countries.map((country) => (
                    <option value={country.name}>{country.name}</option>
                ))}
            </select> <br />
            <label for="html">{label6}</label><br />
            <input type="text" id="typeLbl" placeholder={description} disabled/> 
            <select name="type" id="type" onChange={event => {loadDescription(event.target.value);}} >
                <option value="">{label7}</option>
                {types.map((type) => (
                    <option value={type.typeID}>{type.name}</option>
                ))}
            </select> <br />
            <label for="html">{label8}</label><br />
            <input type="text" id="descLbl" placeholder={type} disabled/> 
            <select name="desc" id="desc" onChange={event => setdescription(event.target.value)} >
                <option value="">{label9}</option>
                {desces.map((desc) => (
                    <option value={desc.descriptionID}>{desc.name}</option>
                ))}
            </select> <br />
            <label for="html">{label10}</label><br />
            <input type="date" id="date" min="2022-01-01" max="2032-12-31" onChange={event => setDate(event.target.value)} placeholder={details.date} required/> <br />
            <label for="html">{label11}</label><br />
            <input type="text" className="inputCmnt" id="comment" onChange={event => setComment(event.target.value)} placeholder={details.comment}/>
        </div>
        </form>
        <Dialog open={open}>
                <DialogTitle>{label13}</DialogTitle>
                <DialogContent>
                    <form className="fileForm">  
                        <input className="inputfile" type="file" name="fileToUpload" id="fileToUpload" onChange={(e) => {handleToClose(); handleClickToOpenDialog(); upload(e, e.target.files[0]);}}/>
                        <label className="inputLabel" for="fileToUpload">{label14}</label><br/><br/>
                        {images.map((image) => (
                        <a href={image}><img src={image} className="imgUpload" alt="Image" width="100" height="100" /></a>
                    ))}<br/>
                    </form> 
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose} color="#286980" autoFocus>{label15}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={loadingDialog}>
                <DialogTitle>{label16}</DialogTitle>
                <DialogContent>
                <div className="loader"></div>
                </DialogContent>
            </Dialog>
    </div>
    )
}

export default EditExpense