import React from "react";
import { useState, useEffect, useContext } from "react";
import dropdown from './img/dropdown.png';
import { useNavigate } from "react-router-dom"


const Admin = () => {

    const history = useNavigate ();

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");
    const [label18, setLabel18] = useState("");
    const [label19, setLabel19] = useState("");
    const [label20, setLabel20] = useState("");

    const userID = localStorage.getItem('id');
    const [userInfo, setUserInfo] = useState([ ]);
    const [companies, setCompanies] = useState([ ]);

    const [open, setOpen] = useState(false);
    const logged = localStorage.getItem('logged');

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                console.log("test");
            }
        };
    }, [history]);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': userID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                if(data.error!="true"){
                    localStorage.setItem("company", data.company);
                    changeLanguage(data.language);
                    setUserInfo(data);
                }
            })
            .catch((error) => {
                localStorage.clear();
                window.location.href='/login';
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':7, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
              setLabel18(data.body[17].label);
              setLabel19(data.body[18].label);
              setLabel20(data.body[19].label);
            }}
        )
        .catch((error) => {}); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
        };
        fetch('https://tem.infokrugsolutions.com/back/api/company/get-companies.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                if(data.error!="true"){
                setCompanies(data.body);
                }
            })
            .catch((error) => {
            });
    }, []);

    function deleteCompany(event){
        let check = window.confirm(label20);

        if(check){
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({'companyID': event}),
            };
            fetch('https://tem.infokrugsolutions.com/back/api/company/delete-company.php', requestOptions)
                .then((response) => {
                    if(!response.ok) throw new Error(response.status);
                    else { return response.json();}
                })
                .then(data => {
                    window.location.href='/superadmin-board';
                })
                .catch((error) => {
                });
        }
    }

    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
    }

    function logoutFun(){
        localStorage.clear();
        window.location.href='/login';
    }

    return(       
        <div className="home">
            <a href="/superadmin-board"><h1>{label1}</h1></a>
            <div className="header">
                <div className="dropdown">
                    <button className="dropbtn">{userInfo.name} {userInfo.surname} <img src={dropdown} /></button>
                    <div className="dropdown-content">
                        <a href="#" onClick={()=> window.location.href='/profile-settings'}>{label18}</a>
                        <a href="#" onClick={logoutFun}>{label19}</a>
                    </div>
                </div>
            </div>
            <div className="actionBar">
                <button className="buttonExpense"  onClick={()=> window.location.href='/superadmin-board/company/create-company'}>{label17}</button>
            </div>
            <div className="visorTable">
                <table className="data">
                    <tr>
                        <th>{label2}</th>
                        <th>{label3}</th>
                        <th>{label4}</th>
                        <th className="firstClass">{label5}</th>
                        <th className="thirdClass">{label6}</th>
                        <th className="firstClass">{label7}</th>
                        <th className="thirdClass">{label8}</th>
                        <th className="secondClass">{label9}</th>
                        <th className="secondClass">{label10}</th>
                        <th>{label11}</th>
                        <th>{label12}</th>
                    </tr>
                    {companies.map((company) => (
                        <tr>
                            <td>{company.cmp}</td>
                            <td>{decodeHTMLEntities(company.name)}</td>
                            <td>{company.address1}</td>
                            <td className="firstClass">{company.address2}</td>
                            <td className="thirdClass">{company.city}</td>
                            <td className="firstClass">{company.postal}</td>
                            <td className="thirdClass">{company.country}</td>
                            <td className="secondClass">{company.tax}</td>
                            <td className="secondClass">{company.vat}</td>
                            <td><button className="basicButton" onClick={event => {localStorage.setItem('companyID', company.id); localStorage.setItem('companyName', company.name); window.location.href='/superadmin-board/company/supervisors';}}>{label13}</button> <button className="basicButton" onClick={event => {localStorage.setItem('companyID', company.id); localStorage.setItem('companyName', company.name); window.location.href='/superadmin-board/company/users';}}>{label14}</button></td>
                            <td><button className="basicButton"  onClick={event => {localStorage.setItem('companyID', company.id); localStorage.setItem('companyName', company.name); window.location.href='/superadmin-board/company/update-company';}}>{label15}</button> <button className="deleteButton" onClick={event => {deleteCompany(company.id);}}>{label16}</button></td>
                        </tr>
                    ))}
                </table> <br />
            </div>
        </div>
    )
}

export default Admin