import { useState, useEffect, useContext } from "react";
import back from './img/back.png';

const CreateCompany = () => {

    const [name, setName] = useState('');
    const [cmp, setCmp] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [postal, setPostal] = useState('');
    const [country, setCountry] = useState('');
    const [tax, setTax] = useState('');
    const [vat, setVat] = useState('');

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");
    const [label12, setLabel12] = useState("");
    const [label13, setLabel13] = useState("");
    const [label14, setLabel14] = useState("");
    const [label15, setLabel15] = useState("");
    const [label16, setLabel16] = useState("");
    const [label17, setLabel17] = useState("");
    const [label18, setLabel18] = useState("");
    const [label19, setLabel19] = useState("");
    const [label20, setLabel20] = useState("");
    const [label21, setLabel21] = useState("");
    const [label22, setLabel22] = useState("");
    const [label23, setLabel23] = useState("");
    const [label24, setLabel24] = useState("");
    const [label25, setLabel25] = useState("");

    const [countries, setCountries] = useState([ ]);

    var headerImg = document.getElementById("headerImg");

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('id')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error!="true"){
                changeLanguage(data.language);
                }
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':8, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(data.error!="true"){
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
              setLabel12(data.body[11].label);
              setLabel13(data.body[12].label);
              setLabel14(data.body[13].label);
              setLabel15(data.body[14].label);
              setLabel16(data.body[15].label);
              setLabel17(data.body[16].label);
              setLabel18(data.body[17].label);
              setLabel19(data.body[18].label);
              setLabel20(data.body[19].label);
              setLabel21(data.body[20].label);
              setLabel22(data.body[21].label);
              setLabel23(data.body[22].label); 
              setLabel24(data.body[23].label); 
              setLabel25(data.body[24].label); 
            }
            }
        )
        .catch((error) => {}); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company':1}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/country/get-countries.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {if(data.error!="true"){ setCountries(data.body)}})
            .catch((error) => {
            });
    }, []);

    function createCompany(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'name': name, 'cmp': cmp, 'address1': address1, 'address2': address2, 'city':city, 'postal':postal, 'country': country, 'tax':tax, 'vat':vat}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/company/create-company.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                    window.location.href='/superadmin-board';
                }
            )
            .catch((error) => {
            }); 
    }

    return (        
        <div className="newTravelExpense">
            <form onSubmit={createCompany}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/superadmin-board'}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="firstLine">
                <h2>{label2}</h2>
                <button type="submit" className="btnSave">{label13}</button>
            </div>
            <div className="inputBox">
                <label for="html">{label3}</label><br /><br />
                <input type="text" placeholder={label4} onChange={event => setName(event.target.value)} required/>
                <input type="text" placeholder={label5} onChange={event => setCmp(event.target.value)} required/> <br /><br />
                <input type="text" placeholder={label6} onChange={event => setAddress1(event.target.value)} required/> 
                <input type="text" placeholder={label7} onChange={event => setAddress2(event.target.value)} /><br /><br />
                <input type="text" placeholder={label8} onChange={event => setCity(event.target.value)} required/><br /><br />
                <input type="text" placeholder={label9} onChange={event => setPostal(event.target.value)} required/><br /><br />
                <select name="country" id="country" onChange={event => setCountry(event.target.value)} required>
                    <option value="">{label10}</option>
                    {countries.map((country) => (
                        <option value={country.name}>{country.name}</option>
                    ))}
                </select><br /><br />
                <input type="text" placeholder={label11} onChange={event => setTax(event.target.value)} required/> 
                <input type="text" className="inputLast" placeholder={label12} onChange={event => setVat(event.target.value)} required/> 
            </div>
            </form>
        </div>
    )
}

export default CreateCompany