import { darkScrollbar } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import back from './img/back.png';

const ProfileSettings = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeat, setRepeat] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [lang, setLang] = useState("");

    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");
    const [label9, setLabel9] = useState("");
    const [label10, setLabel10] = useState("");
    const [label11, setLabel11] = useState("");

    var headerImg = document.getElementById("headerImg");

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('id')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                document.getElementById('1').value= data.username;
                setUsername(data.username);
                document.getElementById('4').value= data.name;
                setName(data.name);
                document.getElementById('5').value= data.surname;
                setSurname(data.surname);
                document.getElementById('6').value= data.email;
                setEmail(data.email);
                setLang(data.language);
                document.getElementById('country').value = data.language;
                changeLanguage(data.language);
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':5, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
              setLabel9(data.body[8].label);
              setLabel10(data.body[9].label);
              setLabel11(data.body[10].label);
            }
        )
        .catch((error) => {}); 
    }

    function updateLang() {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id':localStorage.getItem('id'), 'language':Number(lang)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/update-user-lang.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
            if(localStorage.getItem('role')==0){
                window.location.href='/superadmin-board';
            }
            else if(localStorage.getItem('role')==1){
                window.location.href='/supervisor-board';
            }
            else{
                window.location.href='/home';
            }
            }
        )
        .catch((error) => {}); 
    }

    function updateUser(event){
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('id'), 'username':username, 'email':email, 'name': name, 'surname': surname}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/update-user-data.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
                })
            .then(
                data => { 
                })
            .catch((error) => {
            }); 

        if(password=="" && repeat==""){
            updateLang();
        }
        else{
            if(password==repeat){
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({'id': localStorage.getItem('id'), 'password':password}),
                };
                fetch('https://tem.infokrugsolutions.com/back/api/user/update-user-password.php', requestOptions)
                    .then((response) => {
                        if(!response.ok) throw new Error(response.status);
                        else { return response.json();}
                        })
                    .then(
                        data => { 
                            updateLang();
                        })
                    .catch((error) => {
                    }); 
            }
            else{
                alert(label10);
                document.getElementById('2').value= "";
                setPassword("");
                document.getElementById('3').value= "";
                setRepeat("");
            }
        }
    }

    function backUrl(){
        if(localStorage.getItem('role')==0){
            window.location.href='/superadmin-board';
        }
        else if(localStorage.getItem('role')==1){
            window.location.href='/supervisor-board';
        }
        else{
            window.location.href='/home';
        }
    }

    return (        
        <div className="newTravelExpense">
            <form onSubmit={updateUser}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> backUrl()}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="firstLine">
                <h2> {label2}</h2>
                <button type="submit" className="btnSave">{label9}</button>
            </div>
            <div className="inputBox">
                <label for="html">{label3}</label><br />
                <input type="text" placeholder={label3} id="1" onChange={event => setUsername(event.target.value)} required/><br />
                <label for="html">{label4}</label><br />
                <input type="text" placeholder={label4} id="6" onChange={event => setEmail(event.target.value)} required/><br />
                <label for="html">{label5}</label><br />
                <input type="password" placeholder={label5} id="2" onChange={event => setPassword(event.target.value)} /><br />
                <label for="html">{label6}</label><br />
                <input type="password" placeholder={label6} id="3" onChange={event => setRepeat(event.target.value)} /><br />
                <label for="html">{label7}</label><br />
                <input type="text" placeholder={label7} id="4" onChange={event => setName(event.target.value)} required/><br /> 
                <label for="html">{label8}</label><br />
                <input type="text" id="5" placeholder={label8} onChange={event => setSurname(event.target.value)} required/><br /> 
                <label for="html">{label11}</label><br />
                <select name="country" id="country" onChange={event => setLang(event.target.value)} required>
                    <option value="">{label11}</option>
                    <option value="1">English/Englisch</option>
                    <option value="2">German/Deutsch</option>
                </select><br /><br />
            </div>
            </form>
        </div>
    )
}

export default ProfileSettings