import { useState, useEffect, useContext } from "react";
import ac from './img/ac.png';
import dc from './img/dc.png';
import qu from './img/qu.png';
import logout from './img/shutdown.png';
import dropdown from './img/dropdown.png';
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";


const VisorOrders = () => {
    var label1 = "Supervisor Board";
    var label2 = "Travel expenses";
    var label3 = "Travel orders";
    var label4 = "New travel expense";
    var label11 = "New travel order";
    const userID = localStorage.getItem('id');
    const company = localStorage.getItem("company");
    const [userInfo, setUserInfo] = useState([ ]);

    const [orders, setOrders] = useState([ ]);

    const [country, setCountry] = useState('');
    const [place, setPlace] = useState('');
    const [date, setDate] = useState('');
    const [rtnDate, setRtnDate] = useState('');
    const [arrTime, setArrTime] = useState('');
    const [rtnTime, setRtnTime] = useState('');

    const [date1, setDate1] = useState("");
    const [date2, setDate2] = useState("");
    const [userFilter, setUserFilter] = useState("");
    const [users, setUsers] = useState([ ]);
    const [dates, setDates] = useState([ ]);

    const [open, setOpen] = useState(false);

    const handleClickToOpen = () => {
        setOpen(true);
    };
  
    const handleToClose = () => {
        setOpen(false);
    };

    function getOrders(company) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': company}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/order/get-company-orders.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                setOrders(data.body);
                for(var i=0;i<data.itemCount;i++){
                    dates.push(data.body[i].date);
                    users.push(data.body[i].user.username);
                }
                const dates2 = [...new Set(dates)];
                const users2 = [...new Set(users)];
                setDates(dates2);
                setUsers(users2);
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': userID}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                localStorage.setItem("company", data.company);
                setUserInfo(data);
                getOrders(data.company);
            });
    }, []);

    function logoutFun(){
        localStorage.clear();
        window.location.href='/login';
    }

    function approve(event){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'orderID': event, "approved": 1}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/order/approve-order.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(data => {window.location.href='/supervisor-board/orders';});
    }

    function getOrderById(id1, id2){
        localStorage.setItem("user", id2);
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'userID': id2, 'orderID':id1}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/order/get-order-by-id.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
             })
            .then(data => {
                details(data);
            })
            .catch((error) => {
            });
    }

    function details(order){
        setCountry(order.country);
        setPlace(order.place);
        setDate(order.date);
        setArrTime(order.arrTime);
        setRtnDate(order.rtnDate);
        setRtnTime(order.rtnTime);
        handleClickToOpen();
    }

    function disapprove(event){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'orderID': event, "approved": 0}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/order/approve-order.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
              .then(data => {window.location.href='/supervisor-board/orders';});
    }

    function filterOrder(filter1, filter2, filter3){
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'company': company}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/order/get-company-orders.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
            })
            .then(data => {
                orders.length = 0;
                const help = [];
                if(filter1!="" && filter2!=""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date >= filter1 && data.body[i].date <= filter2){
                            help.push(data.body[i]);
                        }
                    }
                }
                else if(filter1!="" && filter2==""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date >= filter1){
                            help.push(data.body[i]);
                        }
                    }
                }
                else if(filter1=="" && filter2!=""){
                    for(var i=0;i<data.itemCount;i++){
                        if(data.body[i].date <= filter2){
                            help.push(data.body[i]);
                        }
                    }
                }
                else if(filter1=="" && filter2==""){
                    for(var i=0;i<data.itemCount;i++){
                        help.push(data.body[i]);
                    }
                }

                const final = [];

                if(filter3!=""){
                    for(var i=0;i<help.length;i++){
                        if(help[i].user.username == filter3){
                            final.push(help[i]);
                            console.log(filter3);
                        }
                    }
                    setOrders(final);
                }
                else{
                    setOrders(help);
                }
            })
            .catch((error) => {
            });
    }


    function navigation(event){
        if(event=="1"){
            window.location.href='/supervisor-board';
        }
        else if(event=="2"){
            window.location.href='/supervisor-board/orders';
        }
        else if(event=="3"){
            window.location.href='/archive';
        }
    }
   
    return (        
        <div className="orders">
           <h1>{label1}</h1>
           <div className="header">
                <div className="dropdown">
                    <button className="dropbtn">{userInfo.name} {userInfo.surname} <img src={dropdown} /></button>
                    <div className="dropdown-content">
                        <a href="#" onClick={()=> window.location.href='/profile-settings'}>Profile settings</a>
                        <a href="#" onClick={logoutFun}>Logout</a>
                    </div>
                </div>
            </div>
            <div className="actionBar">
            <select className="navigation" name="view" id="view" onChange={event => {navigation(event.target.value)}}>
                    <option value="2">{label3}</option>
                    <option value="1">{label2}</option>
                    <option value="3">Archive</option>
                </select>
                <select className="date" name="view" id="view"  onChange={event => {setDate1(event.target.value); filterOrder(event.target.value, date2, userFilter);}}>
                    <option value="">From date</option>
                    {dates.map((date) => (
                        <option value={date}>{date}</option>
                    ))}
                </select>
                <select className="dateSec" name="view" id="view"  onChange={event => {setDate2(event.target.value); filterOrder(date1, event.target.value, userFilter);}}>
                    <option value="">To date</option>
                    {dates.map((date) => (
                        <option value={date}>{date}</option>
                    ))}
                </select>
                <select className="user" name="view" id="view"  onChange={event => {setUserFilter(event.target.value); filterOrder(date1, date2, event.target.value);}}>
                    <option value="">All users</option>
                    {users.map((us) => (
                        <option value={us}>{us}</option>
                    ))}
                </select>
            </div>
            <div className="dataTable">
                <table className="data">
                    <tr>
                        <th>Username</th>
                        <th className="fourthClass">Country</th>
                        <th>Place</th>
                        <th className="secondClass">Arrival date</th>
                        <th className="secondClass">Return date</th>
                        <th className="thirdClass">Approved</th>
                        <th>Action</th>
                    </tr>
                    {orders.map((order) => (
                        <tr onClick={event => getOrderById(order.orderID, order.userID)}>
                            <td>{order.user.username}</td>
                            <td>{order.paymant}</td>
                            <td className="fourthClass">{order.country}</td> 
                            <td className="firstClass">{order.type}</td>
                            <td className="secondClass">{order.description}</td>
                            <td className="secondClass">{order.date}</td>
                            <td className="thirdClass">{order.approved==null ? <span><img src={qu} alt=""/></span> : <span>{order.approved==1 ? <img src={ac} alt="accepted"/> : <img src={dc} alt="accepted"/>}</span>}</td>
                            <td>{order.approved==null ? <span><button className="appBtn" onClick={event => approve(order.orderID)}>Approve</button> <button className="appBtnD" onClick={event => disapprove(order.orderID)}>Disapprove</button></span> : <span>{order.approved==1 ? <span><button  className="appBtn" disabled>Approve</button> <button className="appBtnD" disabled>Disapprove</button></span> : <span><button className="appBtn" disabled>Approve</button>  <button className="appBtnD" disabled>Disapprove</button></span>}</span>}</td>
                        </tr>
                    ))}
                </table> <br />
            </div>
            <Dialog open={open}>
                <DialogTitle>Travel order - Details</DialogTitle>
                <DialogContent>
                    <div>
                        <div className="detailsView">
                            <p>Country: {country}</p><br/>
                            <p>Place: {place}</p><br/>
                            <p>Arrive date: {date}</p><br/>
                            <p>Arrive time: {arrTime}</p><br/>
                            <p>Return date: {rtnDate}</p><br/>
                            <p>Return time: {rtnTime}</p><br/>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleToClose} color="#286980">CLOSE</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VisorOrders