import React from 'react';
import { useState } from 'react';
import logo from './logo.png';
import Block from './components/Block';
import Home from './components/Home';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import NewTravelExpense from './components/NewTravelExpense';
import NewTravelOrder from './components/NewTravelOrder';
import Bin from './components/Bin';
import Visor from './components/Visor';
import VisorOrders from './components/VisorOrders';
import EditExpense from './components/EditExpense';
import EditExpenseVisor from './components/EditExpenseVisor';
import Admin from './components/Admin';
import VisorList from './components/VisorList';
import UserList from './components/UserList';
import CreateCompany from './components/CreateCompany';
import EditCompany from './components/EditCompany';
import CreateUser from './components/CreateUser';
import EditUser from './components/EditUser';
import ProfileSettings from './components/ProfileSettings';
import Archive from './components/Archive';
import EditOrder from './components/EditOrder';


function App() {
  return (
    <div className="App">
     <Routes>
        <Route path='/login' element = { <Block logo={logo}/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/home/new-travel-expense' element={<NewTravelExpense/>} />
        <Route path='/home/new-travel-order' element={<NewTravelOrder/>} />
        <Route path='/recycle-bin' element={<Bin/>} />
        <Route path='/supervisor-board' element={<Visor/>} />
        <Route path='/supervisor-board/orders' element={<VisorOrders/>} />
        <Route path='/home/edit-travel-expense' element={<EditExpense/>} />
        <Route path='/edit-travel-order' element={<EditOrder/>} />
        <Route path='/supervisor-board/edit-travel-expense' element={<EditExpenseVisor/>} />
        <Route path='/superadmin-board' element={<Admin/>} />
        <Route path='/superadmin-board/company/supervisors' element={<VisorList/>} />
        <Route path='/superadmin-board/company/users' element={<UserList/>} />
        <Route path='/superadmin-board/company/create-company' element={<CreateCompany/>} />
        <Route path='/superadmin-board/company/update-company' element={<EditCompany/>} />
        <Route path='/superadmin-board/company/create-user' element={<CreateUser/>} />
        <Route path='/superadmin-board/company/update-user' element={<EditUser/>} />
        <Route path='/profile-settings' element={<ProfileSettings/>} />
        <Route path='/archive' element={<Archive/>} />
      </Routes> 
    </div>
  );
}

export default App;
