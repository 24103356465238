import { useState, useEffect, useContext } from "react";
import back from './img/back.png';

const EditUser = () => {
    const [label1, setLabel1] = useState("");
    const [label2, setLabel2] = useState("");
    const [label3, setLabel3] = useState("");
    const [label4, setLabel4] = useState("");
    const [label5, setLabel5] = useState("");
    const [label6, setLabel6] = useState("");
    const [label7, setLabel7] = useState("");
    const [label8, setLabel8] = useState("");

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');

    var headerImg = document.getElementById("headerImg");

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('id')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                changeLanguage(data.language);
            });
    }, []);

    function changeLanguage(event) {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'page':10, 'language':Number(event)}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/label/get-labels.php', requestOptions)
        .then((response) => {
            if(!response.ok) throw new Error(response.status);
            else { return response.json();}
          })
        .then(
          data => {
              setLabel1(data.body[0].label);
              setLabel2(data.body[1].label);
              setLabel3(data.body[2].label);
              setLabel4(data.body[3].label);
              setLabel5(data.body[4].label);
              setLabel6(data.body[5].label);
              setLabel7(data.body[6].label);
              setLabel8(data.body[7].label);
            }
        )
        .catch((error) => {}); 
    }

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('editId')}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/get-user-by-id.php', requestOptions)
            .then(response => response.json())
            .then(data => {
                document.getElementById('1').value= data.username;
                setUsername(data.username);
                document.getElementById('2').value= data.password;
                setPassword(data.password);
                document.getElementById('3').value= data.name;
                setName(data.name);
                document.getElementById('4').value= data.surname;
                setSurname(data.surname);
                document.getElementById('5').value= data.email;
                setEmail(data.email);
            });
    }, []);

    function createCompany(event){
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({'id': localStorage.getItem('editId'), 'username':username, 'email':email, 'password': password, 'name': name, 'surname': surname}),
        };
        fetch('https://tem.infokrugsolutions.com/back/api/user/update-user.php', requestOptions)
            .then((response) => {
                if(!response.ok) throw new Error(response.status);
                else { return response.json();}
              })
            .then(
              data => {
                  if(localStorage.getItem('createRole')==1){
                    window.location.href='/superadmin-board/company/supervisors';
                  }
                  else{
                    window.location.href='/superadmin-board/company/users';
                  }
                }
            )
            .catch((error) => {
            }); 
    }

    return (        
        <div className="newTravelExpense">
            <form onSubmit={createCompany}>
            <h1 className="backHeading" id="header" onMouseEnter={()=> headerImg.style.display="inline"} onMouseLeave={()=> headerImg.style.display="none"} onClick={()=> window.location.href='/superadmin-board'}><img src={back} id="headerImg"/> {label1}</h1>
            <div className="firstLine">
                <button type="submit" className="btnSave">{label8}</button>
            </div>
            <div className="inputBox">
                <label for="html">{label3}</label><br />
                <input type="text" placeholder={label3} id="1" onChange={event => setUsername(event.target.value)} required/><br />
                <label for="html">{label4}</label><br />
                <input type="text" placeholder={label4} id="5" onChange={event => setEmail(event.target.value)} required/><br />
                <label for="html">{label5}</label><br />
                <input type="password" placeholder={label5} id="2" onChange={event => setPassword(event.target.value)} required/><br />
                <label for="html">{label6}</label><br />
                <input type="text" placeholder={label6} id="3" onChange={event => setName(event.target.value)} required/><br /> 
                <label for="html">{label7}</label><br />
                <input type="text" className="inputLast" id="4" placeholder={label7} onChange={event => setSurname(event.target.value)} required/> 
            </div>
            </form>
        </div>
    )
}

export default EditUser